import React, { Component } from 'react';

import appStoreBadge from '../assets/images/app-store-badge.png';
import googlePlayBadge from '../assets/images/google-play-badge.png';
import styles from './actionSection.module.css';

export default class ActionSection extends Component {
  render() {
    return (
      <div className={styles.section}>
        <div className={styles.wrapper}>
          <h2 className={styles.header}>Join the community</h2>
          <a
            className={styles.badge}
            href='https://itunes.apple.com/us/app/raterfox/id1451045733?mt=8'>
            <img
              src={appStoreBadge}
              alt='app-store-badge'
              width='auto'
              height='50'
              alt='Apple App Store'
            />
          </a>
          <a
            className={styles.badge}
            href='https://play.google.com/store/apps/details?id=com.raterfox.raterfox'>
            <img
              src={googlePlayBadge}
              alt='google-play-badge'
              width='auto'
              height='50'
              alt='Google Play Store'
            />
          </a>
        </div>
      </div>
    );
  }
}
