import React, { Component } from 'react';

import appStoreBadge from '../assets/images/app-store-badge.png';
import googlePlayBadge from '../assets/images/google-play-badge.png';

import styles from './mobileFooter.module.css';

class MobileFooter extends Component {
  render() {
    return (
      <div className={styles.navBadges}>
        <div className={styles.badgesWrap}>
          <a
            className={styles.badge}
            href='itms-apps://itunes.apple.com/us/app/raterfox/id1451045733?mt=8'>
            <img
              src={appStoreBadge}
              alt='app-store-badge'
              alt='Apple App Store'
              className={styles.badgeImg}
            />
          </a>
          <a
            className={styles.badge}
            href='https://play.google.com/store/apps/details?id=com.raterfox.raterfox'>
            <img
              src={googlePlayBadge}
              alt='google-play-badge'
              alt='Google Play Store'
              className={styles.badgeImg}
            />
          </a>
        </div>
      </div>
    );
  }
}

export default MobileFooter;
