import React from 'react';
import Helmet from 'react-helmet';

export default ({
  pageUrl = 'https://www.facebook.com/raterfox/',
  type = null,
  title = 'RaterFox - Social Recommendation App',
  description = 'The social recommendations app for movies, tv shows & games. Join the community today and start discovering your next favourite thing.',
  image = 'https://s3.eu-central-1.amazonaws.com/recommend-me/extra/raterfox.jpg',
  appID = '1088597931155576'
}) => (
  <Helmet>
    {pageUrl && <meta property='og:url' content={pageUrl} />}
    {type && <meta property='og:type' content={type} />}
    {title && <meta property='og:title' content={title} />}
    {description && <meta property='og:description' content={description} />}
    {image && <meta property='og:image' content={image} />}
    {appID && <meta property='fb:app_id' content={appID} />}
  </Helmet>
);
