import React, { Component } from 'react';
import Headroom from 'react-headroom';

import appStoreBadge from '../assets/images/app-store-badge.png';
import googlePlayBadge from '../assets/images/google-play-badge.png';
import logo from '../assets/images/logo-only-blue.png';

import styles from './header.module.css';

class Home extends Component {
  state = {
    menuIsActive: false
  };

  toggleMenu() {
    this.setState({ menuIsActive: !this.state.menuIsActive });
  }

  render() {
    return (
      <Headroom
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.97)',
          left: 0,
          position: 'fixed',
          verticalAlign: 'middle'
        }}>
        <div className={styles.wrapper}>
          <div className={styles.logo}>
            <img
              src={logo}
              onClick={() => console.log('ewfwerf')}
              alt='raterfox-logo'
              style={{
                maxWidth: 50,
                verticalAlign: 'middle',
                display: 'inline-block',
                marginRight: 10,
                marginTop: -10
              }}
            />
            <h2
              style={{
                display: 'inline-block',
                fontSize: '1.8em'
              }}>
              RaterFox
            </h2>
          </div>
          <a
            className={
              this.state.menuIsActive ? styles.activeMenu : styles.menu
            }
            onClick={() => this.toggleMenu()}
            href='#'>
            <div className={styles.drawer} />
            <div className={styles.drawer} />
            <div className={styles.drawer} />
          </a>
          <div className={styles.body}>
            <ul
              className={
                this.state.menuIsActive ? styles.listOpen : styles.list
              }>
              <li>Privacy</li>
            </ul>
            <div className={styles.navBadges}>
              <a
                className={styles.badge}
                href='https://itunes.apple.com/us/app/raterfox/id1451045733?mt=8'>
                <img
                  src={appStoreBadge}
                  alt='app-store-badge'
                  width='auto'
                  height='50'
                  alt='Apple App Store'
                />
              </a>
              <a
                className={styles.badge}
                href='https://play.google.com/store/apps/details?id=com.raterfox.raterfox'>
                <img
                  src={googlePlayBadge}
                  alt='google-play-badge'
                  width='auto'
                  height='50'
                  alt='Google Play Store'
                />
              </a>
            </div>
          </div>
        </div>
      </Headroom>
    );
  }
}

export default Home;
