import React, { Component } from 'react';

import macbook from '../assets/images/macbook.png';
import styles from './macbookSection.module.css';

export default class MacbookSection extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.textWrapper}>
          <h1>
            Rather stay on the web? Visit the
            <a href='https://www.raterfox.com' target='_blank'>
              Web App
            </a>
            and start discovering.
          </h1>
        </div>
        <a
          className={styles.macbookWrapper}
          href='https://www.raterfox.com'
          target='_blank'>
          <img
            src={macbook}
            alt='macbook'
            style={{
              width: '100%',
              verticalAlign: 'middle',
              display: 'inline-block',
              marginRight: 10,
              marginTop: -15
            }}
          />
        </a>
      </div>
    );
  }
}
