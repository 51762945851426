import React from 'react';
import Helmet from 'react-helmet';

export default ({
  cardType = 'summary_large_image',
  username = 'raterfox',
  title = 'RaterFox - Social Recommendation App',
  description = 'The social recommendations app for movies, tv shows & games. Join the community today and start discovering your next favourite thing.',
  image = 'https://s3.eu-central-1.amazonaws.com/recommend-me/extra/raterfox.jpg'
}) => (
  <Helmet>
    <meta name='twitter:card' content={cardType} />
    {username && <meta name='twitter:creator' content={username} />}
    {title && <meta name='twitter:title' content={title} />}
    {description && <meta name='twitter:description' content={description} />}
    {image && <meta name='twitter:image' content={image} />}
  </Helmet>
);
