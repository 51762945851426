import React, { Component } from 'react';
import { Link } from 'gatsby';

import styles from './footer.module.css';

import iconFacebook from '../assets/images/icon-facebook.svg';
import iconInstagram from '../assets/images/icon-instagram.svg';
import iconPinterest from '../assets/images/icon-pinterest.svg';
import iconYoutube from '../assets/images/icon-youtube.svg';
import iconTwitter from '../assets/images/icon-twitter.svg';

export default class Footer extends Component {
  render() {
    return (
      <div className={styles.footer}>
        <div className={styles.wrapper}>
          <div className={styles.nav}>
            <a href='https://www.raterfox.com' target='_blank'>
              Web App
            </a>
            <a
              href='https://chrome.google.com/webstore/detail/raterfox-popular-movies-t/pbmdibcifmempicdafabdakcoamfobik'
              target='_blank'>
              Chrome extension
            </a>
            <Link to='/support/'>Support</Link>
            <Link to='/privacy/'>Privacy</Link>
          </div>
          <div className={styles.social}>
            <a
              target='_blank'
              className={styles.item}
              href='https://www.facebook.com/raterfox'>
              <img src={iconFacebook} width='10' height='20' alt='Facebook' />
            </a>
            <a
              target='_blank'
              className={styles.item}
              href='https://www.instagram.com/raterfox_official'>
              <img src={iconInstagram} width='22' height='20' alt='Instagram' />
            </a>
            <a
              target='_blank'
              className={styles.item}
              href='https://www.pinterest.com/raterfox'>
              <img src={iconPinterest} width='22' height='20' alt='pinterest' />
            </a>
            <a
              target='_blank'
              className={styles.item}
              href='https://twitter.com/raterfox'>
              <img src={iconTwitter} width='20' height='20' alt='twitter' />
            </a>
            <a
              target='_blank'
              className={styles.item}
              href='https://www.youtube.com/channel/UCtOECM2hrCyYSdNenoq0sAw'>
              <img src={iconYoutube} width='23' height='20' alt='youtube' />
            </a>
          </div>
        </div>
      </div>
    );
  }
}
